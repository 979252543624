<template>
  <div>
    <v-dialog max-width="900" v-model="courseDialog">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?' Update ':" Add "} course`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <v-col cols="12" md="6">
              <v-text-field
                  label="Course title"
                  outlined
                  dense
                  v-model="course.title"
              >

              </v-text-field>
              <span class="text-danger" v-if="$v.course.title.$error">Title is required</span>
            </v-col>
            <v-col cols="3" md="3" v-if="program.has_discipline">
              <!--              <pre>{{course}}</pre>-->
              <v-select
                  :items="disciplines"
                  label="Discipline"
                  outlined
                  dense
                  v-model="course.discipline"
              >

              </v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                  label="Course subject code"
                  outlined
                  dense
                  v-model="course.subject_code"
              >

              </v-text-field>

            </v-col>

            <v-col cols="3" md="3">
              <v-select
                  :items="grades_levels"
                  label="Semester/year"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  v-model="course.grade_id"
              >

              </v-select>
              <span class="text-danger" v-if="$v.course.grade_id.$error">This information is required</span>
            </v-col>
            <v-col cols="3" md="3">
              <v-text-field
                  label="Duration"
                  outlined
                  dense
                  v-model="course.duration"
              >

              </v-text-field>
              <!--                  <span class="text-danger" v-if="$v.course.duration.$error">Duration is required</span>-->
            </v-col>

            <v-col cols="3" md="3">
              <v-select
                  :items="['Hours','Days' ,'Months','Years']"
                  label="Duration unit"
                  outlined
                  dense
                  v-model="course.duration_unit"
              >

              </v-select>
              <!--                  <span class="text-danger" v-if="$v.course.duration_unit.$error">Duration Unit is required</span>-->
            </v-col>

            <v-col cols="3" md="3">
              <v-select
                  :items="['Grade','Percentage']"
                  label="Mark Type"
                  outlined
                  dense
                  v-model="course.mark_type"
              >

              </v-select>
            </v-col>

            <v-col cols="3" md="3">
              <v-text-field
                  label="Theory mark"
                  outlined
                  dense
                  v-model="course.theory_mark"
              >

              </v-text-field>
            </v-col>

            <v-col cols="3" md="3">
              <v-text-field
                  label="Theory pass mark"
                  outlined
                  dense
                  v-model="course.theory_pass_mark"
              >

              </v-text-field>
            </v-col>

            <v-col cols="3" md="3">
              <v-text-field
                  label="Theory credit hours"
                  outlined
                  dense
                  v-model="course.theory_credit_hours"
              >

              </v-text-field>
            </v-col>
            <v-col cols="3" md="3">
              <v-text-field
                  label="Theory grade point"
                  outlined
                  dense
                  v-model="course.theory_grade_points"
              >

              </v-text-field>
            </v-col>
            <div class="col-4  mt-5">
<!--              <b-form-checkbox v-model="course.has_practical" name="check-button" class switch>Has practical ?
              </b-form-checkbox>-->
              Has practical ?
              <v-switch
                  v-model="course.has_practical"
                  :label="course.has_practical ? 'Yes' : 'No'"
                  color="primary"
              ></v-switch>
            </div>
            <div class="col-4  mt-5" v-if="!course.is_internal">
<!--              <b-form-checkbox v-model="course.has_internal_subject" name="check-button" class switch>Has internal
                Subject ?
              </b-form-checkbox>-->
              Has internal Subject ?
              <v-switch
                  v-model="course.has_internal_subject"
                  :label="course.has_internal_subject ? 'Yes' : 'No'"
                  color="primary"
              ></v-switch>
            </div>
            <v-col cols="3" md="3" v-if="course.has_practical">
              <v-text-field
                  label="Practical credit hours"
                  outlined
                  dense
                  v-model="course.practical_credit_hours"
              >

              </v-text-field>
            </v-col>
            <v-col cols="3" md="3" v-if="course.has_practical">
              <v-text-field
                  label="Practical grade point"
                  outlined
                  dense
                  v-model="course.practical_grade_points"
              >

              </v-text-field>
            </v-col>
            <v-col cols="4" md="4" v-if="course.has_practical">
              <v-text-field
                  label="Practical mark"
                  outlined
                  dense
                  v-model="course.practical_mark"
              >

              </v-text-field>
            </v-col>

            <v-col cols="4" md="4" v-if="course.has_practical">
              <v-text-field
                  label="Practical pass mark"
                  outlined
                  dense
                  v-model="course.practical_pass_mark"
              >

              </v-text-field>
            </v-col>
            <v-col cols="4" md="4" v-if="course.has_internal_subject && !course.is_internal">
              <v-select
                  :items="internal_subjects"
                  label="Internal subject"
                  outlined
                  dense
                  item-text="display_text_with_subject_code"
                  item-value="id"
                  v-model="course.internal_course_id"
              >

              </v-select>
            </v-col>


            <div class="col-12 form-group">
              <label for class>Content</label>
              <br/>
              <ckeditor v-model="course.description" :config="editorConfig"></ckeditor>


            </div>

            <div class="col-3  mt-5">
              Optional
              <v-switch
                  v-model="course.is_optional"
                  :label="course.is_optional ? 'Yes' : 'No'"
                  color="primary"
              ></v-switch>
            </div>
            <div class="col-3  mt-5">
              Visible
              <v-switch
                  v-model="course.is_visible"
                  :label="course.is_visible ? 'Yes' : 'No'"
                  color="primary"
              ></v-switch>
            </div>
            <div class="col-3  mt-5">

              Status
              <v-switch
                  v-model="course.is_active"
                  :label="course.is_active ? 'Active' : 'Inactive'"
                  color="primary"
              ></v-switch>
            </div>

            <div class="col-12 text-right mt-4">
              <v-btn
                  class="btn btn-standard"
                  depressed
                  @click="hideModal"
              >Cancel
              </v-btn>
              <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  @click="createAndUpdate"
                  :loading="isBusy"
              >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();

export default {
  props: ["programId", 'course_id', 'grades_levels', 'program', 'disciplines'],
  name: "course-create-and-update",
  validations: {
    course: {
      title: {required},
      grade_id: {required},
      // duration: {required},
      // duration_unit: {required},
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      courseDialog: false,

      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      course: {
        title: null,
        description: null,
        mark_type: 'Grade',
        theory_mark: null,
        theory_pass_mark: null,
        has_practical: null,
        has_internal_subject: null,
        practical_mark: null,
        practical_pass_mark: null,
        subject_code: null,
        program_id: this.programId,
        grade_id: null,
        duration: null,
        duration_unit: 'Days',
        is_optional: false,
        is_visible: true,
        is_active: true
      },
      internal_subjects: []
    };
  },

  methods: {
    getGrades() {
      gradeLevelService.getByProgram(this.programId).then(response => {
        this.gradesLevels = response.data;
      });
    },
    getCourse(id) {
      courseService.show(id).then(response => {
        this.edit = true;
        this.course = response.data.courses;
        this.course.has_practical = this.course.has_practical ? true : false;
        this.course.is_optional = this.course.is_optional ? true : false;
        this.course.is_active = this.course.is_active ? true : false;
        this.course.is_visible = this.course.is_visible ? true : false;
      });
    }, getInternalSubjects() {
      courseService.getInternalCourses(this.program.id).then(response => {
        this.internal_subjects = response.data.courses;
      })
    },
    showModal(id = null) {
      if (id) {
        this.getCourse(id);
      }
      this.getInternalSubjects();
      this.courseDialog = true;
    },
    hideModal() {
      this.teacher = [];
      this.resetFaculty();
      this.courseDialog = false;

    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.course.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateCourse();
        } else {
          this.createCourse();
        }
      }
    },
    createCourse() {
      courseService.store(this.course).then(response => {
        this.resetFaculty()
        this.$snotify.success("Information added");
        this.courseDialog = false;
        this.$emit("refresh");
      });
    },
    updateCourse() {
      courseService.update(this.course.id, this.course).then(response => {
        this.resetFaculty()
        this.$snotify.success("Information updated");
        this.courseDialog = false;
        this.$emit("refresh");
      });
    }, resetFaculty() {
      this.course = {
        title: null,
        program_id: this.programId,
        grade_id: null,
        duration: null,
        duration_unit: null,
        is_optional: true,
        is_visible: true,
        is_active: true,
        has_internal_subject: false,
        internal_subject_id:null
      }
      this.$v.$reset()
    }
  }
};
</script>
