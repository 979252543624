<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="row" v-if="program.financial_fee_structure">
        <div class="col-md-6 text-left">
          <h4>Semester/Grade Fees</h4>
        </div>
        <div class="col-md-6 text-right mb-3">
          <v-btn
            @click="addProgramFee()"
            class="mt-4 btn btn-primary"
            style="color: #fff"
          >
            <v-icon small elevation="2" outlined>fas fa-plus</v-icon>Add fee
          </v-btn>
        </div>

        <div class="col-4" v-if="program.financial_fee_structure == 'yearly'">
          <v-select
            outlined
            dense
            label="Financial Year"
            :items="years"
            v-model="search.financial_year"
            clearable
          ></v-select>
        </div>

        <div class="col-4" v-if="program.financial_fee_structure == 'yearly'">
          <v-select
            outlined
            dense
            label="Grade"
            :items="gradeLevels"
            item-text="title"
            item-value="id"
            v-model="search.grade_id"
            clearable
          ></v-select>
        </div>

        <div class="col-4" v-if="program.financial_fee_structure != 'yearly'">
          <v-select
            :items="academic_years"
            outlined
            dense
            item-text="title"
            item-value="id"
            label="Academic Year"
            v-model="search.academic_year_id"
            clearable
          ></v-select>
        </div>
        <div class="col-4 text-right">
          <v-btn class="btn btn-primary text-white" depressed @click="getProgramFee"
            >Filter</v-btn
          >
        </div>

        <div class="col-12">
          <table class="table">
            <thead>
              <tr class="px-3">
                <th v-if="program.financial_fee_structure != 'yearly'">Academic year</th>
                <th v-if="program.financial_fee_structure == 'yearly'">Financial year</th>
                <th v-if="program.financial_fee_structure == 'yearly'">Grade</th>
                <th>Admission fee</th>
                <th>Is auto increment fee</th>
                <th>Fee</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="programFees.length > 0">
              <tr v-for="(programFee, index) of programFees" :key="index">
                <td v-if="program.financial_fee_structure != 'yearly'">
                  {{ programFee.academic_year_title }}
                </td>
                <td v-if="program.financial_fee_structure == 'yearly'">
                  {{ programFee.financial_year }}
                </td>
                <td v-if="program.financial_fee_structure == 'yearly'">
                  {{ programFee.grade_title || null }}
                </td>
                <td>
                  {{ programFee.admission_fee ? `Rs. ${programFee.admission_fee}` : "NA" }}
                </td>
                <td>
                  <span class="badge" :class="programFee.is_auto_increment_fee ? 'badge-success' : 'badge-danger' ">{{ programFee.is_auto_increment_fee ? 'Yes' : 'No' }}</span>
                  <div v-if="programFee.is_auto_increment_fee">
                    Rs. {{ programFee.auto_increment_fee_amount }}
                  </div>
                </td>

                <td>Rs. {{ programFee.total_fee }}</td>

                <td class="pr-0 text-left">
                  <template>
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="editProgramFee(programFee)">
                            <span class="navi-icon">
                              <i class="fas fa-edit"></i>
                            </span>
                            <span class="navi-text">Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            class="navi-link"
                            @click.prevent="deleteProgramFee(programFee.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text">Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-center" colspan="10">You haven't added any fees.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="programFees.length > 0">
          <div class="col-12 text-center">
            <b-pagination
              @input="getProgramFee"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
            ></b-pagination>
            <!--  -->
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 text-center">
          <h4>Please add program fee structure to continue</h4>
        </div>
      </div>
    </div>
    <create-and-update
      ref="programFee"
      :program="program"
      :academic_years="academic_years"
      :grade-levels="gradeLevels"
      @refresh="getProgramFee"
    ></create-and-update>
  </div>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import ProgramFeeService from "@/core/services/fee/programFee/ProgramFeeService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const gradeLevel = new GradeLevelService();
const programFeeService = new ProgramFeeService();
const academicYearService = new AcademicYearService();

export default {
  components: {
    CreateAndUpdate,
  },
  props: ["program"],
  data() {
    return {
      search: {
        financial_year: "",
        academic_year_id: "",
      },
      programFees: [],
      academic_years: [],
      gradeLevels: [],
      page: null,
      total: null,
      perPage: null,
    };
  },
  mounted() {
    this.getProgramFee();
    this.getAcademicYears();
    this.getGradeLevels();
  },
  computed: {
    years() {
      let value = [];
      let year = new Date().getFullYear();
      value.push(year);
      let i = 1;
      while (i < 5) {
        value.push(year - i);
        i++;
      }
      return value;
    },
  },
  methods: {
    getProgramFee() {
      programFeeService.paginate(this.program.id, this.search).then((response) => {
        this.programFees = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    getAcademicYears() {
      academicYearService.all().then((response) => {
        this.academic_years = response.data;
      });
    },
    getGradeLevels() {
      gradeLevel.getByProgram(this.program.id).then((response) => {
        this.gradeLevels = response.data;
      });
    },
    addProgramFee() {
      this.$refs["programFee"].showModal();
    },
    editProgramFee(programFee) {
      this.$refs["programFee"].showModal(programFee);
    },
    deleteProgramFee(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            programFeeService.delete(this.program.id, id).then((response) => {
              this.getProgramFee();
            });
          }
        },
      });
    },
  },
};
</script>
