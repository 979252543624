<template>
  <v-app>
    <v-dialog persistent
        v-model="dialog" max-width="1000">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Manage program fee
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-3" v-if="program.financial_fee_structure == 'yearly'">
              <v-text-field dense outlined v-model="program_fee.financial_year"
                            label="Financial year"></v-text-field>
              <span class="text-danger"
                    v-if="program.financial_fee_structure == 'yearly' && $v.program_fee.financial_year.$error">This information is required</span>
            </div>
            <div class="col-3" v-if="program.financial_fee_structure != 'yearly'">
              <v-select dense outlined v-model="program_fee.academic_year_id" :items="academic_years" item-text="title"
                        item-value="id"
                        label="Academic year"></v-select>
              <span class="text-danger"
                    v-if="program.financial_fee_structure != 'yearly' && $v.program_fee.academic_year_id.$error">This information is required</span>
            </div>
            <div class="col-3" v-if="program.financial_fee_structure == 'yearly'">
              <v-select dense outlined v-model="program_fee.grade_id" :items="gradeLevels" item-text="title"
                        item-value="id"
                        label="Grade"></v-select>
              <span class="text-danger"
                    v-if="program.financial_fee_structure == 'yearly' && $v.program_fee.grade_id.$error">This information is required</span>
            </div>
            <div class="col-3">
              <v-text-field
                  min="0"
                  type="number"
                  v-model="program_fee.total_fee"
                  prefix="Rs."
                  outlined
                  dense
                  label="Total fee">

              </v-text-field>
              <span class="text-danger" v-if="$v.program_fee.total_fee.$error">Fee  is required</span>
            </div>
            <div class="col-3">
              <v-switch dense v-model="program_fee.is_admission_fee_applicable"
                        label="Admission fee "></v-switch>
            </div>
            <div class="col-3" v-show="program_fee.is_admission_fee_applicable">
              <v-text-field
                  v-model="program_fee.admission_fee"
                  prefix="Rs."
                  outlined
                  dense
                  @input="onPaymentTypeChanged"
                  label="admission fee included">
              </v-text-field>
            </div>


            <div class="col-12 text-right mt-4">
              <b-button class="btn btn-standard cancel-btn" depressed @click="resetForm">Cancel</b-button>
              <v-btn   class="text-white ml-2 btn btn-primary" depressed :loading="isBusy" @click="createAndUpdate">Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>

    </v-dialog>
  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import ProgramFeeService from "@/core/services/fee/programFee/ProgramFeeService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const programFeeService = new ProgramFeeService();
const siteSettingService = new SiteSettingService();

export default {
  name: "program_fee-create-and-update",
  props: ['program', 'academic_years', 'gradeLevels'],
  validations() {
    return {
      program_fee: this.program_rule,
      // installments: this.installment_rule
    }
  },
  // validations: {
  //   program_fee : this
  // },
  data() {
    return {
      dialog: false,
      menu: false,
      isBusy: false,
      menu2: false,
      edit: false,
      levels: [],
      programs: [],
      setting: null,
      no_of_installments: 0,
      program_fee: {
        financial_year: this.current_year,
        grade_id: null,
        total_fee: null,
        program_id: null,
        is_admission_fee_applicable: false,
      }
    };
  },
  computed: {
    frequency() {
      return [
        {
          title: 'Monthly',
          value: 'monthly'
        },
        {
          title: 'Quarterly',
          value: 'quarterly'
        },
        {
          title: 'Semester',
          value: 'semester'
        },
        {
          title: 'Yearly',
          value: 'yearly'
        },
      ];
    },
    program_rule() {
      let rule = {
        total_fee: {required},
      }
      if (this.program.financial_fee_structure != 'yearly') {
        rule.academic_year_id = {required};
      } else {
        rule.grade_id = {required};
        rule.financial_year = {required};
      }
      return rule
    },
    programId() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    current_year() {
      let date = new Date();
      return date.getFullYear();
    }
  },

  mounted() {
    this.getSetting()
    this.program_fee.financial_year = this.current_year;
  },
  methods: {

    getSetting() {
      siteSettingService.show().then(response => {
        this.setting = response.data;
        if (this.setting && this.setting.id) {
        }
      });
    },
    showModal(program_fee = {}) {
      if (program_fee.id) {
        this.program_fee = program_fee
        this.edit = true

      } else {
        this.edit = false;
        this.resetForm();
      }
      if (this.program_fee.payment_type == "distributed") {
        this.installments = []
        this.program_fee.installments.forEach(ele => {
          this.installments.push({amount: ele})
        });
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.dialog = false;
    },
    createAndUpdate() {
      this.program_fee.program_id = this.programId;
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.program.financial_fee_structure == "yearly")
          this.program_fee.financial_fee_structure = this.program.financial_fee_structure
        if (this.edit) {
          this.updateProgramFee();
        } else {
          this.createProgramFee();
        }
      }
    },

    createProgramFee() {
      this.isBusy = true;
      programFeeService.store(this.programId, this.program_fee).then(response => {
        if (!response.data.status)
          this.$snotify.error(response.data.msg)
        else {
          this.resetForm()
          this.$snotify.success("Information added");
        }
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(this.isBusy = false);
    },

    updateProgramFee() {
      this.isBusy = true;

      programFeeService.update(this.programId, this.program_fee.id, this.program_fee).then(response => {
        if (!response.data.status)
          this.$snotify.error(response.data.msg)
        else {
          this.resetForm()
          this.isBusy = false;
          this.$snotify.success("Information updated");
        }
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(this.isBusy = false);
    },

    resetForm() {
      this.program_fee = {
        financial_year: this.current_year,
        grade_id: null,
        total_fee: null,
        program_id: null,
        is_admission_fee_applicable: false,
      }
      this.edit = false
      this.$v.$reset()
      this.hideModal()
      this.$emit("refresh");
    },

    onPaymentTypeChanged() {
      if (this.program_fee.payment_type == 'distributed') {
        let duration = this.program_fee.payment_to_be_made
        let frequency = this.program_fee.payment_frequency;
        // switch (frequency) {
        //   case 'monthly':
        //     this.no_of_installments = (parseFloat(duration * 12) / 1);
        //     break;
        //   case 'quarterly':
        //     this.no_of_installments = (parseFloat(duration * 12) / 3);
        //     break;
        //   case 'semester':
        //     this.no_of_installments = (parseFloat(duration * 12) / 6);
        //     break;
        //   case 'yearly':
        //     this.no_of_installments = (parseFloat(duration * 12) / 12);
        //     break;
        // }
        this.installments = [];
        for (let i = 0; i < duration; i++) {
          let fee = this.program_fee.total_fee
          if (this.program_fee.admission_fee)
            fee = parseInt(fee) - parseInt(this.program_fee.admission_fee)
          let price = parseFloat(parseInt(fee) / parseInt(duration)).toFixed(2)
          this.installments.push({amount: price})
        }
      }
    }
  },
};
</script>
