<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Semester/year</h4>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="openDialog()" class="mt-4 btn btn-primary mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add semester/year
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-r wrap-column">Title</th>
                    <th class="px-r wrap-column">More info</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-show="gradesLevels.length > 0"
                    v-for="(grades, index) of gradesLevels"
                    :key="index"
                  >
                    <td class="px-r wrap-column">{{ grades.title }}</td>
                    <td class="px-r wrap-column">
                      <div>
                        <strong>Display text:</strong> {{ grades.display_text }}
                      </div>
                      
                      <div class="mt-2">
                        <strong>No. of courses taught:</strong> {{ grades.no_of_courses_taught }}
                      </div>

                      <div class="mt-2">
                        <strong>Order:</strong> {{ grades.grade_in_number }}
                      </div>

                      <div class="mt-2">
                        <span
                          class="badge"
                          :class="grades.is_active ? 'badge-success' : 'badge-danger'"
                          >{{ grades.is_active ? "Active" : "Inactive" }}</span
                        >
                      </div>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="openDialog(grades.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="deleteFaculty(grades.id)" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fa fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="gradesLevels.length == 0">
                    <td colspan="3" class="text-center">
                      <strong>Data not available</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="gradesLevels.length > 0"
                class="pull-right mt-7"
                @input="getGrades"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <create-and-update
            ref="grades"
            :grade_id="grade_id"
            :programId="programId"
            @close_dialog="closeDialog"
            @refresh="getGrades"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateOrUpdate";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
const gradeLevelService = new GradeLevelService();

export default {
  name: "Department",
  props: ["programId"],
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      gradesLevels: [],
      grade_id: null,
      page: null,
      total: null,
      perPage: null,
    };
  },
  mounted() {
    this.getGrades();
  },
  methods: {
    getGrades() {
      gradeLevelService.getByProgram(this.programId).then((response) => {
        this.gradesLevels = response.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    openDialog(id) {
      this.grade_id = id;
      this.$refs["grades"].showModal(id);
    },
    closeDialog(id) {
      this.grade_id = id;
      this.$refs["grades"].hideModal();
      this.getGrades();
      this.grade_id = null;
    },
    deleteFaculty(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            gradeLevelService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getGrades();
            });
          }
        },
      });
    },
  },
};
</script>
