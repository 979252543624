<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-1  d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4> Manage admission setting</h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard</router-link>
                \  admission settings
              </div>
            </div>
            <div class="breadcrumb-right" v-if="disable_clone_button">
              <div class="card-toolbar">
                <v-btn v-if="disable_clone_button && checkIsAccessible('pre-registration', 'create')" @click="addPreRegistration()" class="mt-4 btn btn-primary"
                       style="color: #fff">
                  <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                  New admission setting
                </v-btn>&nbsp;&nbsp;

              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <table class="table">
              <thead>
              <tr class="px-3">
                <th>Academic year</th>
                <th>Max certificate</th>
                <th>Shifts</th>
                <th>Academic backgrounds</th>
                <th>Compulsory courses</th>
                <!--            <th>Optional Courses</th>-->
                <th>Status</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
                <tr v-show="pre_registrations.length > 0" v-for="(setting, index) of pre_registrations" :key="index">
                <td class="px-3">
                  <span class="font-weight-medium">
                    {{ setting.academic_year || null }}
                  </span>
                </td>
                <td class="px-3">
                  <span class="font-weight-medium">
                    {{ setting.max_certificate || null }}
                  </span>
                </td>
                <td class="px-3">
                  <span v-if="setting.shifts && setting.shifts.length>0">
                    <span class="font-weight-medium ml-1" v-for="(shift, index) of setting.shifts " :key="index">
                       {{capitalizedText(shift) }} <br>
                    </span>
                  </span>
                </td>
                <td class="px-3">
                  <span v-if="setting.academic_backgrounds && setting.academic_backgrounds.length>0">
                    <span class="font-weight-medium ml-3" v-for="(academic, index) of setting.academic_backgrounds " :key="index">
                                      {{capitalizedText(academic) }}<br></span>
                  </span>
                </td>
                <td class="px-3">
                  <span v-if="setting.compulsory_courses && setting.compulsory_courses.length>0">
                    <span class="font-weight-medium ml-1" v-for="(course, index) of setting.compulsory_courses" :key="index">
                      {{capitalizedText(course) }}<br>
                    </span>
                  </span>
                </td>

                <td>
                  <div class="text--secondary">
                    <span class="font-weight-bold"> Active :</span> &nbsp;
                    <i class="fas" :class="setting.is_active ?'fa-check text-success':'fa-ban text-danger'"></i>
                  </div>
                  <div class="text--secondary">
                    <span class="font-weight-bold"> Hostel facility :</span> &nbsp;
                    <i class="fas"
                        :class="setting.enable_hostel_availability ?'fa-check text-success':'fa-ban text-danger'"></i>
                  </div>
                  <div class="text--secondary">
                    <span class="font-weight-bold"> Declaration :</span> &nbsp;
                    <i class="fas"
                       :class="setting.enable_declaration ?'fa-check text-success':'fa-ban text-danger'"></i>
                  </div>
                  <div class="text--secondary">
                    <span class="font-weight-bold"> Transport facility :</span> &nbsp;
                    <i class="fas"
                       :class="setting.enable_transportation_facility ?'fa-check text-success':'fa-ban text-danger'"></i>
                  </div>

                </td>
                <td class="text-center"  style="width: 70px !important; white-space: pre-wrap;">
                  <template>
                    <b-dropdown size="sm" variant="link"
                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                                right no-flip>
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text v-if="checkIsAccessible('pre-registration', 'edit')" tag="div" class="navi-item">
                          <a class="navi-link" @click="editPreRegistration(setting.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                            <span class="navi-text">Edit</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text v-if="checkIsAccessible('pre-registration', 'delete')" tag="div" class="navi-item">
                          <a class="navi-link" @click="deletePreRegistration(setting.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                            <span class="navi-text">Delete</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text  v-if="checkIsAccessible('pre-registration', 'create')"  tag="div" class="navi-item">
                          <a class="navi-link" @click="clonePreregistration(setting.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-clone"></i>
                                </span>
                            <span class="navi-text">Clone</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </template>
                </td>

              </tr>
                <tr v-show="pre_registrations.length == 0">
                <td class=" text-center" colspan="7">
                  <strong>No data available.</strong>
                </td>
              </tr>
              </tbody>

            </table>
<!--            <b-pagination     v-if="pre_registrations.length > 0"
                              @input="getPreRegistrations"  class="pull-right mt-7"
                              v-model="page"
                              :total-rows="total"
                              :per-page="perPage"
                              first-number
                              last-number
            ></b-pagination>-->
          </div>

        </div>
        <clone-setting :dialog="clone_dialog" :setting_id="setting_id" @close_dialog="onSettingCloned()"></clone-setting>
      </div>
    </div>
  </div>
</template>
<script>
import PreRegistrationSettingService from "@/core/services/level/program/pre-registration-setting/PreRegistrationSettingService";
import CloneSetting from "./CloneSetting"

const preRegistrationSettingService = new PreRegistrationSettingService();
export default {
  components: {
    CloneSetting
  },
  props: ["program_id"],
  data() {
    return {
      pre_registrations: [],
      disable_clone_button: true,
      clone_dialog: false,
      setting_id: null,
      program: {},
      page: null,
      total: null,
      perPage: null,
    };
  },
  mounted() {
    this.getPreRegistrations();
  },
  methods: {
    getPreRegistrations() {
      preRegistrationSettingService.paginate(this.program_id).then(response => {
        this.pre_registrations = response.data.setting;
        this.checkForNotLockedPreregistrationSetting(response.data.setting)
      });
    },

    addPreRegistration() {
      this.$router.push({ name: "program-admission-create", params: { programId: this.program_id } });
    },

    editPreRegistration(admissionId) {
      this.$router.push({ name: "program-admission-update", params: {programId: this.program_id, admissionId: admissionId} });
    },

    deletePreRegistration(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            preRegistrationSettingService.delete(this.program_id, id).then(response => {
              this.getPreRegistrations();
            });
          }
        }
      });
    },
    lockSetting(setting) {
      this.$confirm({
        message: `Are you sure you want to lock this setting?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            setting.is_locked = 1;
            setting.is_active = 0;
            preRegistrationSettingService.update(setting.program_id, setting.id, setting).then(response => {
              this.getPreRegistrations();
              this.displayMessage("success")
            }).catch(error => {
              this.displayMessage("error");
            })
          }
        }
      });
    },
    clonePreregistration(settingId) {
      this.clone_dialog = true;
      this.setting_id = settingId
    },
    onSettingCloned() {
      this.clone_dialog = false;
      this.setting_id = null
      this.getPreRegistrations();
    },
    capitalizedText(text){
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    checkForNotLockedPreregistrationSetting(settings) {
  /*    this.disable_clone_button = false;
      settings.forEach(ele => {
        if (!ele.is_locked) {
          this.disable_clone_button = true;
        }
      });
      if(settings.length == 0){
        this.disable_clone_button = true;
      }*/
    }
  }
};
</script>
