<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <div class="row">
          <div class="col-12 ">
            <h3>Required document field</h3>
            <div class="row mt-3">
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="Passport size photo"
                    color="primary"
                    value="passport size photo"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      Passport size photo
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="SEE school leaving"
                    color="primary"
                    value="see school leaving"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                      SEE school leaving
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="+2 Mark sheet"
                    color="primary"
                    value="+2 mark sheet"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    +2 Mark sheet
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="+2 Character certificate"
                    color="primary"
                    value="+2 character certificate"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    +2 Character certificate
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="+2 migration"
                    color="primary"
                    value="+2 migration"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    +2 Migration
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="+2 provisional"
                    color="primary"
                    value="+2 provisional"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    +2 Provisional
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="+2 transcript"
                    color="primary"
                    value="+2 transcript"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    +2 Transcript
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="see provisional"
                    color="primary"
                    value="see provisional"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    SEE provisional
                    </span>
                  </template>
                </v-checkbox>
              </div>
              <div class="col-6">
                <v-checkbox
                    v-model="programDetail.required_documents"
                    label="see mark sheet"
                    color="primary"
                    value="see mark sheet"
                    hide-details>
                  <template v-slot:label>
                    <span class="font-weight-medium">
                    SEE Mark sheet
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed :loading="loading"
            dark
            @click="updateProgram()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
const program=new ProgramService();
export default {
  props:['program'],
  data(){
    return{
      programDetail:{
        required_documents:[],
      },
      loading:false,
    }
  },
  methods:{
    updateProgram(){
      this.loading=true;
      if(this.programDetail.required_documents.length > 0){
        this.programDetail.required_documents=this.programDetail.required_documents.join();
      }else{
        this.programDetail.required_documents=null
      }
      this.programDetail.is_active=this.program.is_active;
      this.programDetail.display_in_website=this.program.display_in_website;
      this.programDetail.has_discipline=this.program.has_discipline;
      program
          .update(this.program.id,this.programDetail)
          .then(response => {
            this.$snotify.success("Required documents update");
            this.$emit("refresh");
          })
          .catch(error => { })
          .finally(() => {
            this.loading=false;
          });
    },
  },
  mounted() {

    if(this.program && this.program.required_documents){
      if(Array.isArray(this.program.required_documents)){
        this.programDetail.required_documents=this.program.required_documents;
      }else{
        this.programDetail.required_documents=this.program.required_documents.split(',');
      }

    }else{
      this.programDetail.required_documents=[];
    }
  }
}
</script>