<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <div class="row">
          <div class="col-12 ">
            <h3>Character certificate field</h3>
            <div class="row mt-3">
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Student name"
                    color="primary"
                    value="student_name"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Certificate date"
                    color="primary"
                    value="certificate_date"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Program name"
                    color="primary"
                    value="program_name"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Completion date "
                    color="primary"
                    value="completion_date"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Parent name"
                    color="primary"
                    value="parent_name"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Date of birth"
                    color="primary"
                    value="date_of_birth"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Academic year"
                    color="primary"
                    value="academic_year"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Address"
                    color="primary"
                    value="address"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Registration no"
                    color="primary"
                    value="registration_no"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Grade point average (GPA)"
                    color="primary"
                    value="gpa"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Final division"
                    color="primary"
                    value="division"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Final grade"
                    color="primary"
                    value="grade"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Transcript issue no"
                    color="primary"
                    value="transcript_issue_no"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Passed year"
                    color="primary"
                    value="passed_year"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Academic year start"
                    color="primary"
                    value="academic_year_start"
                    hide-details>
                </v-checkbox>
              </div>
              <div class="col-4">
                <v-checkbox
                    v-model="programDetail.character_certificate_fields"
                    label="Academic year end"
                    color="primary"
                    value="academic_year_end"
                    hide-details>
                </v-checkbox>
              </div>
            </div>
          </div>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed :loading="loading"
            dark
            @click="updateProgram()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
const program=new ProgramService();
export default {
  props: ["program_id","program"],
  data(){
    return{
      programDetail:{
        character_certificate_fields:[],
      },
      loading:false,
    }
  },
  methods:{
    updateProgram(){
      this.loading=true;
      if(this.programDetail.character_certificate_fields.length > 0){
        this.programDetail.character_certificate_fields=this.programDetail.character_certificate_fields.join();
      }else{
        this.programDetail.character_certificate_fields=null
      }
      this.programDetail.is_active=this.program.is_active;
      this.programDetail.display_in_website=this.program.display_in_website;
      this.programDetail.has_discipline=this.program.has_discipline;
      program
          .update(this.program_id,this.programDetail)
          .then(response => {
            this.$snotify.success("Character certificate fields update");
            this.$emit("refresh");
          })
          .catch(error => { })
          .finally(() => {
            this.loading=false;
          });
    },

  },
  mounted() {
    if(this.program && this.program.character_certificate_fields){
      this.programDetail.character_certificate_fields=this.program.character_certificate_fields.split(',');
    }

  }
}
</script>