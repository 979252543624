<template>
  <v-app>
    <v-dialog
        v-model="dialog" width="600px" persistent>
      <v-card>
        <v-card-title>
          Clone Setting
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-select label="Academic Year" :items="academic_years"  outlined dense item-value="id"
                        item-text="title"
                        :error="$v.academic_year_id.$error"
                        v-model="academic_year_id"></v-select>
              <span class="text-danger"
                    v-if="$v.academic_year_id.$error">Academic Year is required</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard" depressed @click="closeDialog()">Close</v-btn>
          <v-btn class="btn btn-primary text-white" depressed @click="validateForm()">Clone</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import PreRegistrationSettingService
  from "@/core/services/level/program/pre-registration-setting/PreRegistrationSettingService";


const preRegistration = new PreRegistrationSettingService();
const academicYearService = new AcademicYearService();

export default {
  name: "CloneSetting",
  props: ['dialog', 'setting_id'], validations: {
    academic_year_id: {required}
  }, data() {
    return {
      academic_years: [],
      academic_year_id: null
    }
  },
  mounted() {
    this.getAcademicYears();
  },
  methods: {
    getAcademicYears() {
      academicYearService.all().then(response => {
        this.academic_years = response.data;
      })
    }, validateForm() {
      this.$v.academic_year_id.$touch();
      if (this.$v.academic_year_id.$error) {
        setTimeout(() => {
          this.$v.academic_year_id.$reset();
        }, 3000)
      } else {
        this.cloneSetting();
      }
    }, cloneSetting() {
      this.$confirm({
        message: `Are you sure you want to clone this setting?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            preRegistration.cloneSetting(this.setting_id, this.academic_year_id).then(response => {
              this.displayMessage("success");
              this.closeDialog();
            }).catch(error => {
              this.displayMessage("error")
            })
          }
        }
      });
    },
    closeDialog() {
      this.$emit("close_dialog")
    }
  }
}
</script>

<style scoped>

</style>