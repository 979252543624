<template>
  <v-app>
    <div class="card">
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <div class="breadcrumb-left">
            <h4>Program courses</h4>
          </div>
          <div class="breadcrumb-right">
            <v-btn @click="create()" class="mt-4 btn btn-primary mr-1" dark>
              <i class="fa fa-plus"></i>
              Add course
            </v-btn>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <v-row class="px-2">

            <v-col cols="12" md="6">
              <v-select v-on:keyup.enter="getCourses()" :items="grades_levels" class="form-control" v-model="search.grade_id"
                        label="Grade" item-value="id" item-text="title" outlined clearable  dense>
              </v-select>
            </v-col>

            <v-col cols="12" md="6" class="text-right">
              <v-btn
                  @click="getCourses()"
                  :loading="loading"
                  class="btn btn-primary w-35 float-right" dark
              >
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div class="table-responsive">
          <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

          <v-skeleton-loader v-if="loading" type="table-row-divider@25">
          </v-skeleton-loader>
          <table class="table" v-if="!loading">
            <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">Title</th>
                <th class="px-3 wrap-column">Internal subject</th>
                <th class="px-3 wrap-column">Discipline</th>
                <th class="px-3 wrap-column">More info</th>
                <th class="px-3 wrap-column">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(course, index) of courses" :key="index">
                <td class="px-3 wrap-column">
                  {{ course.title }}
                  <span v-if="course.is_internal">(IN)</span>

                  <div class="mt-2">
                    <strong>Subject code:</strong> {{ course.subject_code }}
                  </div>

                  <div class="mt-2">
                    <strong>Duration:</strong> {{ course.duration ? course.duration : 'NA'  }} {{ course.duration_unit }}
                  </div>
                </td>
                <td>
                  <span
                    class="badge badge-primary text-uppercase"
                    v-if="course.discipline"
                    >{{ course.discipline }}</span
                  >
                  <span v-else>-</span>
                </td>
                <td>{{ course.internal_course_title }}</td>
                <td>
                  <div class="mt-2">
                    <strong>Level:</strong> {{ course.grade.title }}
                  </div>
                  <div class="mt-2">
                    <div
                      class="badge"
                      :class="course.is_optional ? 'badge-info' : 'badge-warning'"
                      >{{ course.is_optional ? "Optional" : "Compulsory" }}</div
                    >
                    <div
                      class="badge mt-2"
                      :class="course.is_visible ? 'badge-success' : 'badge-danger'"
                      >{{ course.is_visible ? "Visible" : "Hidden" }}</div
                    >
                    <div
                      class="badge mt-2 mb-2"
                      :class="course.is_active ? 'badge-success' : 'badge-danger'"
                      >{{ course.is_active ? "Active" : "Inactive" }}</div
                    >
                  </div>
                </td>

                <td class="px-3">
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                  >
                    <template v-slot:button-content>
                      <slot>
                        <span>
                          <i class="flaticon-more-1"></i>
                        </span>
                      </slot>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover">
                      <b-dropdown-text
                        tag="div"
                        class="navi-item"
                        v-if="program.additional_assessments"
                      >
                        <a
                          href="#"
                          class="navi-link"
                          @click="manageAdditionalAssessment(course.id)"
                        >
                          <span class="navi-icon">
                            <i class="fas fa-cogs"></i>
                          </span>
                          <span class="navi-text"> Additional Assessment </span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a href="#" class="navi-link" @click="openDialog(course.id)">
                          <span class="navi-icon">
                            <i class="flaticon-edit"></i>
                          </span>
                          <span class="navi-text"> Edit</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a href="#" class="navi-link" @click="deleteCourse(course.id)">
                          <span class="navi-icon">
                            <i class="fas fa-trash"></i>
                          </span>
                          <span class="navi-text"> Delete</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </td>
              </tr>
              <tr v-if="courses.length == 0">
                <td class="px-3 text-center" colspan="6">No data available.</td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-if="courses.length > 0"
            class="pull-right mt-7"
            @input="getCourses"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
          ></b-pagination>
        </div>
      </div>
      <create-and-update
        ref="course"
        :course_id="course_id"
        :programId="programId"
        :program="program"
        :grades_levels="grades_levels"
        :disciplines="disciplines"
        @close_dialog="closeDialog"
        @refresh="getCourses"
      ></create-and-update>
      <course-create
        :program_id="programId"
        :program="program"
        :course_create_dialog="course_create_dialog"
        :grades_levels="grades_levels"
        :disciplines="disciplines"
        @refresh="getCourses()"
      ></course-create>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateOrUpdate";
import CoursesService from "@/core/services/courses/CoursesService";
import CourseCreate from "./Create";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();

export default {
  name: "Courses",
  props: ["programId", "program"],
  components: {
    CreateAndUpdate,
    CourseCreate,
  },
  data() {
    return {
      courses: [],
      grades_levels: [],
      search: {
        grade_id: ''
      },
      course_id: null,
      page: null,
      total: null,
      perPage: null,
      course_create_dialog: false,
      loading: false,
    };
  },
  mounted() {
    this.getCourses();
  },
  computed: {
    disciplines() {
      return this.program.disciplines;
    },
  },
  methods: {
    getGrades() {
      gradeLevelService.getByProgram(this.programId).then((response) => {
        this.grades_levels = response.data;
      });
    },
    getCourses() {
      this.course_create_dialog = false;
      this.loading = true;
      courseService.getByProgram(this.programId, this.search).then((response) => {
        this.courses = response.data.courses;
        this.getGrades();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    create() {
      this.course_create_dialog = true;
    },
    openDialog(id) {
      this.course_id = id;
      this.$refs["course"].showModal(id);
    },
    closeDialog(id) {
      this.course_id = id;
      this.$refs["course"].hideModal();
      this.getFaculties();
      this.course_id = null;
    },
    deleteCourse(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            courseService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getCourses();
            });
          }
        },
      });
    },
    manageAdditionalAssessment(id) {
      this.$router.push({ name: "course-assessment", params: { id: id } });
    },
  },
};
</script>
