<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h4>
              <router-link :to="{ name: 'program' }" class="mr-3">
                <i class="fas fa-arrow-left"></i>
              </router-link>
              <span v-if="program.title">{{ program.title }}</span>
              <span v-else>Add new</span>
            </h4>
          </div>

          <div class="col-md-6 text-right" v-if="tab <= 6">
            <v-btn
              v-if="checkIsAccessible('program', 'create') || checkIsAccessible('program', 'edit')"
              class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
              depressed
              :disabled="sizeExceed"
              @click="saveOrUpdate"
              :loading="isBusy"
              >Save
            </v-btn>
          </div>
        </div>
        <div class="row" v-if="$v.program.$error">
          <div
            class="col-6 offset-2 alert alert-danger"
            v-for="(error, index) of $v.program"
            :key="index"
            v-if="displayError(index)"
          >
            <span class="font-weight-bold">
              {{ `${displayError(index)}` }}
            </span>
          </div>
        </div>

        <!-- <pre>{{tab}}</pre> -->
        <div class="row mt-5">
          <div class="col-12">
            <v-tabs vertical center-active v-model="tab">
              <v-tab class="justify-start">
                <v-icon left> fas fa-clock </v-icon>
                General
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-info-circle </v-icon> Additional info
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-tasks </v-icon>
                Admission process
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-calendar-alt </v-icon>
                Semester/Year
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-book-open </v-icon>

                Courses
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-university </v-icon>

                Admission</v-tab
              >

              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-cog </v-icon>

                Admission application setting</v-tab
              >
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-certificate </v-icon>

                Id card</v-tab
              >
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-money-check-alt </v-icon>

                Fees
              </v-tab>
              <!--              <v-tab class="justify-start" v-if="program.id">Additional Assessment</v-tab>-->
              <!--                            <v-tab class="justify-start" v-if="program.id">Sample Question</v-tab>-->
              <!--                            <v-tab class="justify-start" v-if="program.id">Entrance Questionnaires</v-tab>-->

              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-code-branch </v-icon> Seo setting
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-certificate </v-icon> Character certificate fields
              </v-tab>
              <v-tab class="justify-start" v-if="program.id">
                <v-icon left> fas fa-file </v-icon> User required documents
              </v-tab>
              <v-tab-item>
                <div class="d-block text-center ma-2">
                  <div class="row p-4">
                    <v-col cols="12" class="text-left">
                      <h4>General information</h4>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        dense
                        label="Long name"
                        v-model="program.title"
                      ></v-text-field>
                      <span class="text-danger" v-if="$v.program.title.$error"
                        >This information is required</span
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        dense
                        label="Short name"
                        v-model="program.short_title"
                      ></v-text-field>
                      <!-- <span class="text-danger" v-if="$v.program.short_title.$error">Short Title is required</span> -->
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        dense
                        label="Abbreviation"
                        v-model="program.abstract"
                      ></v-text-field>
                      <span class="text-danger" v-if="$v.program.abstract.$error"
                        >This information is required</span
                      >
                    </v-col>

                    <v-col md="6">
                      <v-select
                        :items="levels"
                        item-value="id"
                        item-text="title"
                        outlined
                        dense
                        label="Level"
                        v-model="program.level_id"
                      ></v-select>
                      <span class="text-danger" v-if="$v.program.level_id.$error"
                        >This information is required</span
                      >
                    </v-col>

                    <v-col md="6">
                      <v-select
                        :items="departments"
                        item-value="id"
                        item-text="title"
                        outlined
                        dense
                        label="Managing department"
                        v-model="program.department_id"
                      ></v-select>
                      <span class="text-danger" v-if="$v.program.department_id.$error"
                        >This information is required</span
                      >
                    </v-col>
                    <v-col md="6">
                      <v-select
                        v-model="program.affiliated_to"
                        :items="institutions"
                        dense
                        outlined
                        label="Affiliated to"
                        item-value="id"
                        item-text="title"
                      ></v-select>
                    </v-col>

                    <v-col md="12" class="text-left">
                      <v-select
                        multiple
                        v-model="selected"
                        :items="downloads"
                        label="Link download category"
                        outlined
                        deletable-chips
                        small-chips
                        dense
                        item-text="title"
                        item-value="id"
                        name="downloads"
                      >
                      </v-select>
                    </v-col>

                    <v-col cols="12" class="text-left">
                      <label for>Admission eligibility</label>
                      <ckeditor
                        v-model="program.admission_eligibility"
                        :config="editorConfig"
                      ></ckeditor>
                    </v-col>
                    <v-col cols="12" class="text-left">
                      <v-textarea
                        outlined
                        dense
                        label="Text for enrolment submission email"
                        v-model="program.summary_message"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="12" class="text-left">
                      <v-text-field
                        outlined
                        dense
                        label="Position"
                        v-model="program.position"
                      ></v-text-field>
                      <!--                                            <span-->
                      <!--                                                    class="text-danger"-->
                      <!--                                                    v-if="$v.program.position.$error"-->
                      <!--                                            >Position is required</span>-->
                    </v-col>
                    <v-col cols="12" md="12" class="text-left">
                      <label for="">Coordinator</label>
                      <template v-if="program.coordinator_id">
                        <v-card class="mx-auto mb-2" max-width="400">
                          <v-card-actions>
                            <v-list-item class="grow">
                              <v-list-item-avatar color="grey darken-3">
                                <v-img
                                  class="elevation-6"
                                  alt=""
                                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                                ></v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title>{{
                                  program.coordinator.suggestion_list_name
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  program.coordinator.email
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card-actions>
                        </v-card>
                      </template>
                      <v-autocomplete
                        v-model="program.coordinator_id"
                        :items="users"
                        :loading="isBusy"
                        outlined
                        dense
                        :search-input.sync="searchName"
                        item-value="id"
                        item-text="display_text"
                        label="User CRN or email"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                  <div class="row px-4">
                    <v-col cols="12" md="4">
                      <v-select
                        :items="['Semester', 'Year']"
                        hint="Grade year level"
                        label="Programming duration type"
                        v-model="program.grade_title"
                        outlined
                        dense
                      ></v-select>
                      <span class="text-danger" v-if="$v.program.grade_title.$error"
                        >This information is required</span
                      >
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Duration"
                        v-model="program.duration"
                        outlined
                        dense
                      ></v-text-field>
                      <span class="text-danger" v-if="$v.program.duration.$error"
                        >This information is required</span
                      >
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-select
                        :items="attendance_types"
                        item-value="value"
                        item-text="title"
                        label="Attendance type"
                        v-model="program.attendance_type"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <hr />
                    <v-col cols="12" md="12" class="text-left">
                      <label for="">Finance</label>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        :items="financial_fee_structures"
                        item-value="value"
                        item-text="title"
                        label="Financial fee structure"
                        v-model="program.financial_fee_structure"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12"> </v-col>
                    <v-col cols="12" md="4" class="text-left">
                      <!--                        <label for>Status</label>-->

                      <v-switch
                        v-model="program.display_in_website"
                        color="primary"
                        label="Display in website"
                      >
                      </v-switch>
                    </v-col>
                    <v-col cols="12" md="4" class="text-left">
                      <v-switch
                        v-model="program.has_discipline"
                        color="primary"
                        label="Has discipline"
                      >
                      </v-switch>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="text-left"
                      v-if="program.has_discipline"
                    >
                      <!--                        <label for>Status</label>-->
                      <v-combobox
                        outlined
                        dense
                        v-model="program.disciplines"
                        name="check-button"
                        class
                        switch
                        multiple
                        chips
                        label="Disciplines"
                        :error="$v.program.disciplines.$error"
                        >Has Discipline
                      </v-combobox>
                      <span class="text-danger" v-if="$v.program.disciplines.$error"
                        >Disciplines is required</span
                      >
                    </v-col>
                    <v-col cols="12" md="4" class="text-left">
                      <!--                        <label for>Status</label>-->

                      <v-switch
                        v-model="program.is_active"
                        color="primary"
                        label="Status"
                      >
                      </v-switch>
                      <span class="text-danger" v-if="$v.program.is_active.$error"
                        >This information is required</span
                      >
                    </v-col>

                    <v-col cols="12" md="12" class="text-left">
                      <v-file-input
                        label="Featured Image"
                        v-model="program.feature_image"
                        outlined
                        dense
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        accept="image/*"
                        @change="checkSize($event)"
                      ></v-file-input>
                      <span class="text-danger" v-if="sizeExceed"
                        >Please upload a file upto 20MB</span
                      >
                    </v-col>
                    <div class="col-md-6 text-left" v-if="program.feature_image_path">
                      <img :src="program.feature_image_path['thumb']" alt="" />
                    </div>
                  </div>
                  <div class="row px-4">
                    <v-col cols="12" md="12">
<!--                      <v-combobox
                        v-model="program.additional_assessments"
                        outlined
                        dense
                        label="Additional assessments"
                        multiple
                        chips
                      ></v-combobox>-->
                      <v-combobox
                                   v-model="program.additional_assessments"
                                   hide-selected outlined dense deletable-chips
                                   placeholder="Additional assessments" label="Additional assessments"
                                   multiple  small-chips>
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                Type the Additional assessments and press <kbd>ENTER</kbd>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                  </div>
                </div>
                <!--                <pre>{{program}}</pre>-->
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <v-row>
                    <v-col cols="12" class="text-left m-4">
                      <v-expansion-panels v-model="open_details" multiple>
                        <draggable
                          v-model="programDetails"
                          @change="sortProgramDetails()"
                          style="width: 100%"
                          tag="div"
                        >
                          <v-expansion-panel
                            v-for="(detail, i) in programDetails"
                            :key="i"
                            v-if="!detail.is_admission_process_info"
                          >

                            <v-expansion-panel-header>
                              <div class="d-flex justify-space-between">
                                <strong
                                  ><i class="cursor-drag fas fa-sort handle pr-2"></i>
                                  {{ detail.title }}
                                  <i
                                    class="fas fa-circle"
                                    :class="
                                      detail.is_active ? 'dot-active' : 'dot-inactive'
                                    "
                                  ></i>
                                </strong>
                                <span class="mr-4">
                                  <a
                                    class=""
                                    href="#"
                                    title=" Add New Section"
                                    v-if="i == programDetails.length - 1"
                                    @click.prevent.stop="addDetails(i, 'details')"
                                    outlined
                                  >
                                    <i class="fa fa-plus-circle m-2 text-success"></i>
                                    <strong class="m-1"> Add new section </strong>
                                  </a>
                                  <a
                                    title="Remove Section"
                                    href="#"
                                    class=""
                                    @click="removeDetails(i)"
                                  >
                                    <i class="fa fa-trash mr-2 text-danger"></i>
                                  </a>
                                </span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row class="details-program-add">
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Title"
                                    v-model="detail.title"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-select
                                    v-model="detail.type"
                                    outlined
                                    dense
                                    :items="[
                                      'none',
                                      'details',
                                      'career',
                                      'admissions',
                                      'pathways',
                                      'fees',
                                      'structure',
                                      'scholarship',
                                      'overview',
                                    ]"
                                    item-value="value"
                                    item-text="title"
                                    label="Type"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Position"
                                    v-model="detail.position"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <b-form-checkbox
                                    v-model="detail.is_active"
                                    name="check-button"
                                    class
                                    switch
                                    >Status
                                  </b-form-checkbox>
                                </v-col>
                                <v-col cols="12" md="12">
                                  Description
                                  <ckeditor
                                      v-if="detail.type != 'structure' && detail.type != 'details'"
                                    v-model="detail.description"
                                    :config="editorConfig"
                                  ></ckeditor>
                                  <ckeditor
                                      v-else
                                    v-model="detail.description"
                                    :config="editorConfig1"
                                  ></ckeditor>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <v-btn
                                    color="primary"
                                    class="m-2 md-12 remove-btn"
                                    v-if="i > 0"
                                    @click="removeDetails(i)"
                                    outlined
                                  >
                                    <i class="fa fa-times mr-2"></i> Remove Section
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    class="m-2 md-12 add-btn"
                                    v-if="i == programDetails.length - 1"
                                    @click="addDetails(i, 'details')"
                                    outlined
                                  >
                                    <i class="fa fa-plus-circle mr-2"></i> Add New Section
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </draggable>
                      </v-expansion-panels>
                    </v-col>
                    <div class="col-md-12 text-right" v-if="tab <= 3">
                      <v-btn
                        class="btn btn-standard text-gray"
                        @click="resetForm"
                        depressed
                        >Cancel
                      </v-btn>
                      <v-btn
                        class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                        depressed
                        :disabled="sizeExceed"
                        @click="saveOrUpdate"
                        :loading="isBusy"
                        >Save
                      </v-btn>
                    </div>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <v-row>
                    <v-col cols="12" class="text-left m-4">
                      <v-expansion-panels
                        v-model="open_details"
                        multiple
                        v-if="programAdmissionDetails.length"
                      >
                        <draggable
                          v-model="programDetails"
                          @change="sortProgramDetails()"
                          style="width: 100%"
                          tag="div"
                        >
                          <v-expansion-panel
                            v-for="(detail, i) in programAdmissionDetails"
                            :key="i"
                          >
                            <!--                            <pre>{{programDetails}}</pre>-->

                            <v-expansion-panel-header>
                              <div class="d-flex justify-space-between">
                                <strong
                                  ><i class="cursor-drag fas fa-sort handle pr-2"></i>
                                  {{ detail.title }}
                                  <i
                                    class="fas fa-circle"
                                    :class="
                                      detail.is_active ? 'dot-active' : 'dot-inactive'
                                    "
                                  ></i>
                                </strong>
                                <span class="mr-4">
                                  <a
                                    class=""
                                    href="#"
                                    title=" Add New Section"
                                    v-if="i == programAdmissionDetails.length - 1"
                                    @click.prevent.stop="
                                      addDetails(i, 'admission_process')
                                    "
                                  >
                                    <i class="fa fa-plus-circle m-2 text-success"></i>
                                    <strong class="m-1"> Add New Section </strong>
                                  </a>
                                  <a
                                    title="Remove Section"
                                    href="#"
                                    class=""
                                    @click="removeDetails(i)"
                                  >
                                    <i class="fa fa-trash mr-2 text-danger"></i>
                                  </a>
                                </span>
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row class="details-program-add">
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Title"
                                    v-model="detail.title"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-select
                                    v-model="detail.type"
                                    outlined
                                    dense
                                    :items="[
                                      'none',
                                      'details',
                                      'career',
                                      'admissions',
                                      'pathways',
                                      'fees',
                                      'structure',
                                      'scholarship',
                                      'overview',
                                    ]"
                                    item-value="value"
                                    item-text="title"
                                    label="Type"
                                  >
                                  </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field
                                    label="Position"
                                    v-model="detail.position"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                  <b-form-checkbox
                                    v-model="detail.is_active"
                                    name="check-button"
                                    class
                                    switch
                                    >Status
                                  </b-form-checkbox>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <ckeditor
                                    v-model="detail.description"
                                    :config="editorConfig"
                                  ></ckeditor>
                                </v-col>
                                <v-col cols="12" md="12">
                                  <v-btn
                                    color="primary"
                                    class="m-2 md-12 remove-btn"
                                    v-if="i > 0"
                                    @click="removeDetails(i)"
                                    outlined
                                  >
                                    <i class="fa fa-times mr-2"></i> Remove Section
                                  </v-btn>
                                  <v-btn
                                    color="primary"
                                    class="m-2 md-12 add-btn"
                                    v-if="i == programAdmissionDetails.length - 1"
                                    @click="addDetails(i, 'admission_process')"
                                    outlined
                                  >
                                    <i class="fa fa-plus-circle mr-2"></i> Add New Section
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </draggable>
                      </v-expansion-panels>
                      <div v-else>
                        <a
                          class=""
                          href="#"
                          title=" Add New Section"
                          @click.prevent.stop="
                            addDetails(programDetails.length, 'admission_process')
                          "
                          outlined
                        >
                          <i class="fa fa-plus-circle m-2 text-success"></i>
                          <strong class="m-1"> Add New Section </strong>
                        </a>
                      </div>
                    </v-col>
                    <div class="col-md-12 text-right" v-if="tab <= 3">
                      <v-btn
                        class="btn btn-standard text-gray"
                        @click="resetForm"
                        depressed
                        >Cancel
                      </v-btn>
                      <v-btn
                        class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                        depressed
                        :disabled="sizeExceed"
                        @click="saveOrUpdate"
                        :loading="isBusy"
                        >Save
                      </v-btn>
                    </div>
                  </v-row>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <div class="row">
                    <v-col cols="12">
                      <grades-level :programId="program.id"></grades-level>
                    </v-col>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <div class="row">
                    <v-col cols="12">
                      <Courses :programId="program.id" :program="program"></Courses>
                    </v-col>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <div class="row p-4">
                    <v-col cols="12" md="12" class="text-left">
                      <!--                        <label for>Display in Enrollment</label>-->
                      <v-switch
                        v-model="program.display_in_enrollment"
                        flat
                        label="Admission open"
                      ></v-switch>
                      <!-- <span class="text-danger" v-if="$v.program.is_active.$error">Status is required</span> -->
                    </v-col>
                    <v-col cols="12" md="6" class="text-left">
                      <v-select
                        :items="closing_types"
                        placeholder="Type"
                        v-model="program.closing_type" @change="changeClosingType"
                        class="form-control"
                        item-value="value"
                        item-text="title"
                        outlined
                        dense
                        label="Admission opening type"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="text-left" v-if="program.closing_type=='open_by_time'">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            label="Admission form closing date"
                            v-model="program.closing_date"  @click:clear="clearClosingDate()"
                            v-bind="attrs"
                            v-on="on" clearable
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="program.closing_date"
                          @input="menu3 = false"
                          header-color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            label="University entrance date"
                            v-model="program.entrance_date"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="program.entrance_date"
                          @input="menu2 = false"
                          header-color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" class="text-left">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            label="University form closing date"
                            v-model="program.form_closing_date"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="program.form_closing_date"
                          @input="menu2 = false"
                          header-color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
<!--                    <v-col cols="12" md="6" class="text-left">
                      <v-select
                        chips
                        :items="document_types"
                        multiple
                        chip
                        label="Document Required for the program"
                        v-model="program.required_documents"
                        outlined
                        dense
                      ></v-select>
                    </v-col>-->
                    <!--                                        <v-col cols="12">-->
                    <!--                                            <h5>University Link</h5>-->
                    <!--                                        </v-col>-->
                    <v-col cols="12" md="6" class="text-left">
                      <v-text-field
                        label="Admission open link"
                        v-model="program.admission_open_link"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-switch
                        v-model="program.use_admission_open_link"
                        flat
                        label="Use admission open link"
                      >
                      </v-switch>
                    </v-col>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <div class="row">
                    <v-col cols="12">
                      <pre-registration-setting
                          v-if="program.id"
                          :program_id="program.id"
                      ></pre-registration-setting>
                    </v-col>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-card class="m-2" flat>
                  <v-card-text>
                    <div class="row">
                      <v-col cols="12">
                        <h4>ID card</h4>
                      </v-col>
                      <v-col cols="12" md="6" class="text-left">
                        <v-select
                            :items="templates"
                            item-value="id"
                            item-text="file_name"
                            label="ID card template"
                            v-model="program.id_card_id"
                            outlined
                            dense
                        ></v-select>
                      </v-col>
                    </div>

                    <div class="row">
                      <div class="col-12 ">
                        <h3>ID card field</h3>
                        <div class="row mt-3">


                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Student photo"
                                color="primary"
                                value="student_photo"
                                hide-details>
                            </v-checkbox>
                          </div>
                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Student ID"
                                color="primary"
                                value="student_id"
                                hide-details>
                            </v-checkbox>
                          </div>
                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Student name"
                                color="primary"
                                value="student_name"
                                hide-details>
                            </v-checkbox>
                          </div>

                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Parent name"
                                color="primary"
                                value="parent_name"
                                hide-details>
                            </v-checkbox>
                          </div>
                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Date of birth"
                                color="primary"
                                value="date_of_birth"
                                hide-details>
                            </v-checkbox>
                          </div>
                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Academic year"
                                color="primary"
                                value="academic_year"
                                hide-details>
                            </v-checkbox>
                          </div>

                          <div class="col-4">
                            <v-checkbox
                                v-model="program.id_card_fields"
                                label="Registration no"
                                color="primary"
                                value="registration_no"
                                hide-details>
                            </v-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 text-right">
                        <v-btn
                            class="text-white ml-2 btn btn-primary"
                            depressed
                            :loading="isBusy"
                            dark
                            @click="updateIdCardFields()"
                        >
                          Update
                        </v-btn>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <div class="d-block text-center">
                  <div class="row">
                    <v-col cols="12">
                      <CourseFees :program="program"></CourseFees>
                    </v-col>
                  </div>
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="row">
                  <div class="col-12">
                    <v-card outlined elevation="3" min-height="523">
                      <v-card-text>
                        <div class="d-block text-center">
                          <div class="row">
                            <v-col cols="12" class="d-flex flex justify-end">
                              <v-btn
                                class="text-white btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
                                depressed
                                :disabled="sizeExceed"
                                @click="saveOrUpdate"
                                :loading="isBusy"
                                >Save
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <h4>Seo settings</h4>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field
                                outlined
                                v-model="program.seo_title"
                                dense
                                label="Seo title"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-combobox
                                outlined
                                small-chips
                                dense
                                deletable-chips
                                v-model="program.seo_keyword"
                                label="Seo keywords"
                                multiple
                              ></v-combobox>
                            </v-col>
                            <v-col cols="12">
                              <v-textarea
                                outlined
                                small-chips
                                dense
                                v-model="program.seo_description"
                                label="Seo description"
                                multiple
                              ></v-textarea>
                            </v-col>
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
                <character-certificate
                  v-if="program.id && !programLoading"
                  :program="program"
                  @refresh="getProgram(program.id)"
                  :program_id="program.id"
                ></character-certificate>
              </v-tab-item>
              <v-tab-item>
                <required-documents
                  v-if="program.id && !programLoading"
                  :program="program"
                  @refresh="getProgram(program.id)"
                  :program_id="program_id"
                ></required-documents>
              </v-tab-item>
              <!--              <v-tab-item>-->
              <!--                <div class="d-block text-center">-->
              <!--                  <div class="row">-->
              <!--                    <v-col cols="12">-->
              <!--                      <additional-assessment v-if="program.id"-->
              <!--                                             :programId="program.id"></additional-assessment>-->
              <!--                    </v-col>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </v-tab-item>-->
              <!--                            <v-tab-item>-->
              <!--                                <div class="d-block text-center">-->
              <!--                                    <div class="row">-->
              <!--                                        <v-col cols="12">-->
              <!--                                            <sample-question v-if="program.id"-->
              <!--                                                             :program="program.abstract"></sample-question>-->
              <!--                                        </v-col>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </v-tab-item>-->
              <!--                            <v-tab-item>-->
              <!--                                <div class="d-block text-center">-->
              <!--                                    <div class="row">-->
              <!--                                        <v-col cols="12">-->
              <!--                                            <questionnaire v-if="program.id" :program_id="program.id"></questionnaire>-->
              <!--                                        </v-col>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </v-tab-item>-->
            </v-tabs>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import DepartmentService from "@/core/services/department/DepartmentService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import { required } from "vuelidate/lib/validators";
import DownloadService from "@/core/services/download/DownloadService";
import ConfigService from "@/core/services/config/ConfigService";
import TemplatesService from "@/core/services/templates/TemplatesService";
import InstitutionService from "@/core/services/institution/InstitutionService";
import Questionnaire from "./questionnaire/Index";
import CourseFees from "./fees/Index";
import SampleQuestion from "./sample-question/Index";
import GradesLevel from "./GradesLevel/GradesLevel";
import Courses from "./Courses/Courses";
import GradePoint from "../grade-point/Index";
import draggable from "vuedraggable";
import PreRegistrationSetting from "./pre-registration-setting/Index";
import CharacterCertificate from "@/view/pages/program/certificate/CharacterCertificate.vue";
import RequiredDocuments from "@/view/pages/program/required-document/RequiredDocuments.vue";
import event from "@/core/plugins/event";
import UserService from "@/core/services/user/UserService";

const institution = new InstitutionService();
const downloadService = new DownloadService();
const programService = new ProgramService();
const levelService = new LevelService();
const configService = new ConfigService();
const departmentService = new DepartmentService();
const templatesService = new TemplatesService();
const userService = new UserService();
export default {
  name: "program-create-edit",
  props: ["program_id"],
  components: {
    draggable,
    GradesLevel,
    Courses,
    Questionnaire,
    CourseFees,
    SampleQuestion,
    GradePoint,
    PreRegistrationSetting,
    CharacterCertificate,
    RequiredDocuments,
  },
  validations() {
    return {
      program: this.program_rule,
    };
  },
  data() {
    return {
      tab: null,
      levels: [],
      document_types: [],
      downloads: [],
      templates: [],
      departments: [],
      institutions: [],
      open_details: [],
      users: [],
      menu2: false,
      menu3: false,
      menu4: false,
      sizeExceed: false,
      isBusy: false,
      edit: false,
      selected: [],
      search: {
        name: null,
      },
      searchName: null,
      editorData: "<p>Content of the editor.</p>",
      closing_types: [
        { title: "Always Open", value: "open_everytime" },
        { title: "Fixed Date", value: "open_by_time" },
      ],
      attendance_types: [
        { title: "Daily Attendance", value: "day" },
        { title: "Class Attendance", value: "subject" },
      ],
      financial_fee_structures: [
        { title: "Yearly", value: "yearly" },
        { title: "Batch", value: "batch" },
      ],
      programDetails: [
        {
          title: null,
          short_title: null,
          abstract: null,
          entrance_exam_fee: null,
          course_fee: null,
          feature_image: null,
          position: null,
          exam_fee: null,
          level_id: null,
          download_id: null,
          description: null,
          is_active: true,
          display_in_enrollment: true,
          admission_eligibility: null,
        },
      ],

      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
            "Image"
          ],
        ],
      },
      editorConfig1: {
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
            "Image"
          ],
        ],
      },

      program: {
        title: null,
        short_title: null,
        abstract: null,
        entrance_exam_fee: null,
        course_fee: null,
        feature_image: null,
        position: null,
        exam_fee: null,
        level_id: null,
        department_id: null,
        download_ids: null,
        description: null,
        is_active: true,
        entrance_date: null,
        form_closing_date: null,
        seo_title: null,
        seo_keyword: [],
        seo_description: null,
        display_in_website: null,
        has_discipline: null,
        disciplines: null,
        affiliated_to: null,
        closing_date: null,
        closing_type: null,
        id_card_width: null,
        id_card_height: null,
      },
      space: {
        top: 0,
        left: 0,
        right: 0,
      },
      programLoading: false,
    };
  },
  mounted() {
    this.getLevels();
    if (this.id) {
      this.edit = true;
      this.getProgram(this.id);
      this.getTemplates();
    }
    this.getDownloads();
    this.getDocumentTypes();
    this.getDepartments();
    this.getAllInstitutions();
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    programAdmissionDetails: function () {
      return this.programDetails.filter((detail) => detail.is_admission_process_info);
    },
    program_rule() {
      let rule = {
        title: { required },
        abstract: { required },
        level_id: { required },
        department_id: { required },
        is_active: { required },
        grade_title: { required },
        duration: { required },
      };
      if (this.program.has_discipline) {
        rule.disciplines = { required };
      }
      return rule;
    },
  },
  watch: {
    searchName(newValue, oldValue) {
      this.search.name = newValue;
      // Lazily load input items
      this.getUsers();
    },
  },
  methods: {
    getUsers() {
      this.isBusy = true;
      userService.paginate(this.search).then((response) => {
        this.isBusy = false;
        this.users = response.data.data;
        this.users.map((ele) => {
          if ( ele.personal_id && ele.personal_id != undefined && ele.personal_id != null) {
            ele.display_text = `${ele.personal_id} - ${ele.full_name} - ${ele.email}`;
            this.users.push(ele);
          }
        });
        // this.page = response.data.meta.current_page;
        // this.total = response.data.meta.total;
        // this.perPage = parseInt(response.data.meta.per_page);
        // alert(this.length)
      });
    },
    getDownloads() {
      downloadService.all().then((response) => {
        this.downloads = response.data.data;
      });
    },
    getTemplates() {
      templatesService.getByProgram(this.id, { type: "id_card" }).then((response) => {
        this.templates = response.data;
      });
    },
    getAllInstitutions() {
      institution.all().then((response) => {
        this.institutions = response.data.data;
      });
    },

    getDepartments() {
      departmentService.all().then((response) => {
        this.departments = response.data.data;
      });
    },
    getDocumentTypes() {
      configService.documentTypes().then((response) => {
        this.document_types = response.data;
      });
    },
    getLevels() {
      levelService.all().then((response) => {
        this.levels = response.data;
      });
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    addDetails(i, type) {
      this.open_details.length = 0;
      this.open_details.push(i + 1);
      let admission_process = true;
      type == "admission_process"
        ? (admission_process = true)
        : (admission_process = false);
      this.programDetails.push({
        title: null,
        description: null,
        is_active: true,
        is_admission_process_info: admission_process,
        position: null,
      });
    },
    buildSelected() {
      let ids = [];
      ids = this.program.download_ids ? this.program.download_ids : [];
      if (ids.length > 0) {
        ids = ids.split(",");
        ids.forEach((ele) => {
          this.selected.push(parseInt(ele));
        });
      }
    },
    removeDetails(i) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.programDetails.splice(i, 1);
          }
        },
      });
    },
    getProgram(id) {
      this.programLoading = true;
      programService
        .show(id)
        .then((response) => {
          this.program = response.data.program;
          if (this.program.seo_keyword) {
            this.program.seo_keyword = this.program.seo_keyword.split(",");
          }
          if (this.program.certificate_space) {
            this.space = JSON.parse(this.program.certificate_space);
          }
          if (this.program.details.length > 0) {
            this.programDetails = this.program.details;
          } else {
            this.programDetails = [
              {
                title: null,
                description: null,
                is_active: true,
                position: null,
              },
            ];
          }

          this.program.is_active ? (this.program.is_active = true) : false;
          this.buildSelected();
        })
        .catch((error) => {})
        .finally(() => {
          this.programLoading = false;
        });
    },

    saveOrUpdate() {

      this.$v.program.$touch();
      if (this.$v.program.$error) {
        setTimeout(() => {
          this.$v.program.$reset();
        }, 10000);
        this.$snotify.error("Validation failed");
      } else {
        if (this.program.seo_keyword && this.program.seo_keyword.length) {
          this.program.seo_keyword = this.program.seo_keyword.join(",");
        }
        if (this.program.additional_assessments && this.program.additional_assessments.length > 0 && Array.isArray(this.program.additional_assessments)) {
          this.program.additional_assessments = this.program.additional_assessments.join(",");
        }

        this.program.certificate_space = JSON.stringify(this.space);

        let fd = this.generateFd();

        this.program.details = this.programDetails;
        this.program.id ? this.updateProgram(fd) : this.saveProgram(fd);
      }
    },
    sortProgramDetails() {
      this.programDetails = this.programDetails.map((details, i) => {
        details.position = i + 1;
        return details;
      });
    },
    saveProgram(fd) {
      this.isBusy = true;
      programService
        .store(fd)
        .then((response) => {
          let data = response.data.program

          if(response.data.status == "OK") {
            event.emit("refresh-program", { id: data.id });
              this.$snotify.success("Information added");
              this.isBusy = false;
              this.$tabs.close().then(() => {
                this.$tabs.open({
                  name: "program-update",
                  params: { id: data.id },
                });
              });
              this.resetForm();
          }
        })
        .catch((err) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    updateProgram(fd) {
      this.isBusy = true;

      programService
        .update(this.program.id, fd)
        .then((response) => {
          this.$snotify.success("Information updated");
          this.isBusy = false;
          this.resetForm();
          this.$tabs.close().then((response) => {
            this.$router.push({
              name: "program-update",
              params: { id: response.data.program.id },
            });
          });
        })
        .catch((err) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.program) {
        if (
          key == "feature_image" &&
          this.program["feature_image"] &&
          this.program["feature_image"] != null
        ) {
          fd.append("feature_image", this.program[key]);
        } else if (
          key == "marksheet_background_image" &&
          this.program["marksheet_background_image"] &&
          this.program["marksheet_background_image"] != null
        ) {
          fd.append("marksheet_background_image", this.program[key]);
        } else if (
          key == "marksheet_second_page_image" &&
          this.program["marksheet_second_page_image"] &&
          this.program["marksheet_second_page_image"] != null
        ) {
          fd.append("marksheet_second_page_image", this.program[key]);
        } else if (key == "is_active") {
          fd.append("is_active", this.program["is_active"] ? 1 : 0);
        } else if (key == "display_in_website") {
          fd.append("display_in_website", this.program["display_in_website"] ? 1 : 0);
        } else if (key == "show_second_page") {
          fd.append("show_second_page", this.program["show_second_page"] ? 1 : 0);
        } else if (key == "display_in_enrollment") {
          fd.append(
            "display_in_enrollment",
            this.program["display_in_enrollment"] ? 1 : 0
          );
        } else if (key == "use_admission_open_link") {
          fd.append(
            "use_admission_open_link",
            this.program["use_admission_open_link"] ? 1 : 0
          );
        }else if (key == "closing_date") {
          fd.append(
              "closing_date",
              this.program["closing_date"] ? this.program["closing_date"] : ''
          );
        } else if (key === "details") {
          fd.append("details", JSON.stringify(this.programDetails));
        } else if (
          this.program[key] != undefined &&
          this.program[key] != null &&
          this.program[key] != null
        ) {
          fd.append(key, this.program[key]);
        }
      }
      fd.append("download_ids", this.selected);
      return fd;
    },
    resetForm() {
      this.program = {
        title: null,
        abstract: null,
        entrance_exam_fee: null,
        course_fee: null,
        exam_fee: null,
        level_id: null,
        is_active: true,
      };
      this.programDetails = [
        {
          title: null,
          description: null,
          is_active: true,
          position: null,
        },
      ];
      this.$v.program.$reset();
    },
    clearClosingDate(){
      if(this.program.closing_date){
        this.program.closing_date=null;
      }
    },
    redirectTo() {
      this.$router.push({ name: "program" });
    },
    changeClosingType(){
      if(this.program.closing_type=='open_everytime'){
        this.program.closing_date=null;
      }

    },
    displayError(index) {
      let message = {
        duration: "Duration is required",
        grade_title: "Program Duration is required",
        title: "Title is required",
        abstract: "Abbreviation is required",
        entrance_exam_fee: "Entrance Exam Fee is required",
        course_fee: "Course Fee is required",
        position: "Position is required",
        exam_fee: "Exam Fee is required",
        level_id: "Level is required",
        department_id: "Department is required",
      };

      if (message[index] && this.$v.program[index].$error) {
        return message[index];
      }
      return false;
    },
    updateIdCardFields() {
      this.isBusy = true;

      let data = {
        id: this.program.id,
        id_card_width: this.program.id_card_width,
        id_card_height: this.program.id_card_height,
        id_card_id: this.program.id_card_id,
        has_discipline: this.program.has_discipline,
        is_active: this.program.is_active ? true : false,
        display_in_website: this.program.display_in_website ? true : false,
        id_card_fields: this.program.id_card_fields ? this.program.id_card_fields.join() : null,
      }
      programService
          .update(this.program.id, data)
          .then(response => {
            if(response.data.status == "OK") {
              this.$snotify.success("ID card fields update");
              this.getProgram(this.program,id)
            }
            this.isBusy = false;
          })
          .catch(() => { this.isBusy=false; })
    },
  },
};
</script>
