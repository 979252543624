<template>
  <v-app>
    <v-dialog max-width="600" v-model="dialog">
      <v-card>
        <v-card-title>
          {{ popupTitle(edit) }}
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-6">
              <v-text-field outlined dense v-model="gradeLevel.title"
                            label="Semester/year title (E.g Semester I, Year XI..)"></v-text-field>
              <span class="text-danger" v-if="$v.gradeLevel.title.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-text-field outlined dense v-model="gradeLevel.display_text"
                            label="Display text title (E.g Semester I, Year XI..)"></v-text-field>
              <span class="text-danger" v-if="$v.gradeLevel.display_text.$error">This information is required</span>
            </div>
            <div class="col-6">
              <v-text-field outlined dense v-model="gradeLevel.grade_in_number"
                            label="Semester/year in number (E.g 1,2,3..)"></v-text-field>
            </div>

            <div class="col-6">
              <v-text-field outlined dense v-model="gradeLevel.no_of_courses_taught"
                            label="No of courses taught"></v-text-field>
            </div>


            <div class="col-3  mt-5">
              <v-switch v-model="gradeLevel.is_active" name="check-button" class switch>Status
              </v-switch>
            </div>

            <div class="col-12 text-right mt-4">
              <v-btn
                  class="btn btn-standard"
                  depressed
                  @click="hideModal"
              >Cancel
              </v-btn>
              <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  @click="createAndUpdate"
                  :loading="isBusy"
              >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";

const gradeLevelService = new GradeLevelService();

export default {
  props: ["programId",],
  name: "gradeLevel-create-and-update",
  validations: {
    gradeLevel: {
      title: {required},
      display_text: {required},
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      dialog: false,
      gradeLevel: {
        title: null,
        display_text: null,
        program_id: this.programId,
        no_of_courses_taught:null,
        is_active: true,
        grade_in_number: 1
      }
    };
  },
  methods: {
    popupTitle(edit) {
      if (edit) {
        return 'Update semester/year'
      } else {
        return 'Add semester/year'
      }
    },
    getGrades(id) {
      gradeLevelService.show(id).then(response => {
        this.edit = true;
        this.gradeLevel = response.data.grades;
        this.gradeLevel.is_active = this.gradeLevel.is_active ? true : false
      });
    },
    showModal(id = null) {
      if (id) {
        this.edit = true;
        this.getGrades(id);
      } else {
        this.edit = false;
        this.resetGrade()
      }
      this.dialog = true;
    },
    hideModal() {
      this.teacher = [];
      this.dialog = false;

      this.resetGrade()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.gradeLevel.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateGrade();
        } else {
          this.createGrade();
        }
      }
    },
    createGrade() {
      gradeLevelService.store(this.gradeLevel).then(response => {
        this.resetGrade()
        this.$snotify.success("Semester/Year Added");
        this.dialog = false;
        this.$emit("refresh");
      });
    },
    updateGrade() {
      gradeLevelService.update(this.gradeLevel.id, this.gradeLevel).then(response => {
        this.resetGrade()
        this.$snotify.success("Semester/Year Updated");
        this.dialog = false;
        this.$emit("refresh");
      });
    }, resetGrade() {
      this.gradeLevel = {
        title: null,
        is_active: true,
        program_id: this.programId,
        no_of_courses_taught:null,
        grade_in_number: 1
      }
      this.$v.$reset()
    }
  }
};
</script>
