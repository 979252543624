<template>
  <div>
    <v-dialog max-width="1200" v-model="course_create_dialog" persistent>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Courses
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="4" md="4">
<!--              <pre>{{course}}</pre>-->
              <v-select
                  :items="grades_levels"
                  label="Semester/year"
                  item-text="title"
                  item-value="id"
                  outlined
                  dense
                  v-model="course.grade_id"
                  :error="$v.course.grade_id.$error"
              >

              </v-select>
              <span class="text-danger" v-if="$v.course.grade_id.$error">This information is required</span>
            </v-col>
            <v-col cols="3" md="3" v-if="program.has_discipline">
<!--              <pre>{{course}}</pre>-->
              <v-select
                  :items="disciplines"
                  label="Discipline"
                  outlined
                  dense
                  v-model="course.discipline"
              >
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-switch v-model="course.is_active" label="Status"></v-switch>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) of courses" :key="index">
            <v-col cols="4">
              <v-text-field :error="$v.courses.$each[index].subject_code.$error" v-model="item.subject_code" outlined
                            dense label="Course subject code"></v-text-field>
              <span class="text-danger"
                    v-if="$v.courses.$each[index].subject_code.$error">This information is required</span>
            </v-col>

            <v-col cols="4">
              <v-text-field :error="$v.courses.$each[index].title.$error" v-model="item.title" outlined dense
                            label="Course Title"></v-text-field>
              <span class="text-danger" v-if="$v.courses.$each[index].title.$error">This information is required</span>
            </v-col>
            <v-col cols="2">
              <v-switch v-model="item.is_internal" label="Is internal"></v-switch>
          </v-col>


            <v-col cols="2">

<!--              <v-btn v-if="courses.length > 1" class="btn btn-secondary mr-2" depressed @click="removeCourse(index)">
                <i class="fas fa-trash"></i>
              </v-btn>-->
              <v-btn v-if="courses.length > 1"
                     class="mx-1" small
                     fab @click="removeCourse(index)"
                     dark
                     color="pink"
              >
                <v-icon dark x-small>
                  fas fa-trash
                </v-icon>
              </v-btn>
<!--              <v-btn v-if="(courses.length-1) == index " class="btn btn-primary " depressed @click="addCourse()">
                <i class="fas fa-plus"></i>
              </v-btn>-->
              <v-btn v-if="(courses.length-1) == index "
                  class="mx-1" small
                  fab @click="addCourse()"
                  dark
                  color="indigo"
              >
                <v-icon dark small>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard"
              depressed
              @click="closeDialog"
          >Cancel
          </v-btn>
          <v-btn
              class="text-white ml-2 btn btn-primary"
              depressed
              @click="createCourse"
              :loading="isBusy"
          >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import CoursesService from "@/core/services/courses/CoursesService";

const courseService = new CoursesService();
export default {
  name: "Create",
  props: ['program_id', 'course_create_dialog', 'grades_levels', 'disciplines', 'program'],
  validations: {
    course:
        {
          grade_id: {required},
          program_id: {required}
        },
    courses: {
      $each: {
        title: {required},
        subject_code: {required},
      }
    }
  },
  data() {
    return {
      isBusy: false,
      course: {grade_id: null},
      courses: [{
        title: null,
        subject_code: null
      }],
    }
  }, methods: {
    closeDialog() {
      this.course = {
        grade_id: null
      }
      this.courses = [{
        title: null,
        subject_code: null
      }];
      this.$v.$reset();
      this.$emit('refresh')
    },
    addCourse() {
      this.courses.push({
        title: null,
        subject_code: null
      })
    }, removeCourse(index) {
      this.courses.splice(index, 1)
    },
    createCourse() {
      this.course.courses = this.courses;
      this.course.program_id = this.program_id;
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.isBusy = true
        courseService.createMultipleCourse(this.course).then(response => {
          this.isBusy = false
          this.$snotify.success("Courses Information added");
          this.closeDialog();
        }).catch(error => {
          this.isBusy = false
          this.$snotify.error("Something went wrong please try again later");
        })
      }
    },
  }
}
</script>

<style scoped>

</style>